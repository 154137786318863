* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
    background-color: $bg_color_primary;
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
a,
p,
input {
    font-family: $font_primary;
    color: $color_primary;
}

//Texts Styles

//Width
.fm_main_title,
.fm_simple_title,
.fm_breadcrumb,
.fm_paragraph,
.fm_tb_name,
.fm_tb_cell--subcell,
.fm_tb_cell--subcell--result {
    font-weight: $fw_bold;
}

.fm_tb_cell {
    font-weight: $fw_regular;
}

//Size
.fm_breadcrumb,
.fm_paragraph,
.fm_tb_name,
.fm_tb_cell--subcell,
.fm_tb_cell,
.fm_tb_cell--subcell--result {
    font-size: $fs_S;
}

.fm_main_title {
    font-size: $fs_L;
}

.fm_simple_title {
    font-size: $fs_M;
}

//Logo
.fm-logo {
    background-image: $logo;
    background-repeat: no-repeat;
    background-size: cover;
    width: $logo_w_m;
    height: $logo_h_m;

    @media (max-width: $d_medium) {
        width: $logo_w_s;
        height: $logo_h_s;
    }

    &--big {
        background-image: $logo;
        background-repeat: no-repeat;
        background-size: cover;
        width: $logo_w_XL;
        height: $logo_h_XL;

        @media (max-width: $d_medium) {
            width: $logo_w_L;
            height: $logo_h_L;
        }
    }
}

//Button
.fm-button {
    color: $color_button_primary;
    border-radius: $border_r_8;
    font-size: $fs_S;

    &--innerSpacing {
        margin-right: 10px;
    }

    &--link {
        background: none;
        color: $color_button_primary;
        font-size: $fs_S;
        cursor: pointer;
        text-decoration: none;

        &:hover {
            color: darken($color_button_primary, 30);
            background-color: none;
        }

        &.MuiButton-root{
            background-color: none;
            color: $color_button_primary;
            font-size: $fs_S;
            cursor: pointer;
            text-decoration: none;
            font-family: $font_primary;
            text-transform: capitalize;
            &:hover {
                color: darken($color_button_primary, 30);
                background-color: #f000;
            }
        }
        

        &--border {
            &.MuiButton-root {
                height: 40px;
                border-radius: $border_r_8;
                box-shadow: none;
                text-transform: capitalize;
                font-size: $fs_S;
                color: $color_button_primary;
                line-height: $fs_S;

                &:hover {

                    border-color: darken($color_button_primary, 10);
                    color: darken($color_button_primary, 10);
                }
            }

            &.active {
                background-color: $color_button_primary;
                color: $color_text_white;

                &:hover {
                    background-color: darken($color_button_primary, 10);
                    color: $color_text_white;
                }
            }
        }

        &--it {
            .fa-xl {
                margin-right: 10px
            }
        }

        &--icon {
            height: 24px;
            width: 24px;
            background: none;
            border: none;
            color: $color_secondary;
            cursor: pointer;

            &:hover {
                color: darken($color_button_primary, 15);
            }
        }
    }

    &--colored {
        &.MuiButton-root {
            background-color: $color_button_primary;
            height: 40px;
            border-radius: $border_r_8;
            box-shadow: none;
            text-transform: capitalize;
            font-size: $fs_S;

            &:hover {
                background-color: darken($color_button_primary, 10);
            }
        }
    }
}

.fm_input {
    .MuiOutlinedInput-root {

        border-radius: $border_r_8;
        width: 100%;
        height: 40px;
        font-size: $fs_S;
        color: $color_text_gray;
        border-color: $color_button_primary;

        input {
            font-size: $fs_S;
            border: none;
        }
    }

    .MuiInputLabel-root {
        font-size: $fs_S;

    }

    &--icon {
        margin: 0;
    }
}

//Tooltip
.tooltip {
    position: relative;
    display: inline-block;

}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 80px;
    background-color: $bg_color_tertiary;
    color: $color_primary;
    text-align: center;
    border-radius: 6px;
    padding: 2px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -40px;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px;
    line-height: $fs_S;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: $bg_color_tertiary transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

//Containers
.fm_main_container {
    max-width: 100vw;
    margin-top: 60px;
    padding: 0 60px;

    &--topheader {
        max-width: 100vw;
        padding: 0 60px;
        margin: 0;
        background-color: $bg_color_primary;
        height: 60px;
    }
}

.fm_card {
    background-color: $bg_color_secondary;
    padding: $padding_l;
    border-radius: $border_r_8;
    box-shadow: $box_shadow;
    z-index: 1;
    margin-bottom: 30px;

    .MuiBox-root {
        padding: 0;
    }
}

.fm_mainTitle {
    margin-top: 40px;
    margin-bottom: 30px;

    h1 {
        font-size: $fs_L;
    }

    &--home {
        margin-top: 60px;
        margin-bottom: 40px;
    }

    &--backpage {
        cursor: pointer;
        gap: 10px;
        font-size: $fs_S;
        height: 14px;
        align-items: center;
    }

    &__bc {
        a {
            color: $color_text_gray;
            text-decoration: none;
            font-weight: $fw_bold;
            cursor: pointer;

            &:hover {
                color: darken($color_text_gray, 16);
            }
        }

        &--np {
            color: $color_text_black;
            font-weight: $fw_regular;
            font-size: $fs_S;
        }
    }


    &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: darken($color_text_green, 15);
        border-radius: 50%;
        background-color: $color_tertiary;
        height: 60px;
        width: 60px;
        margin-right: 24px;

        @media (max-width: $d_medium) {
            display: none;
        }
    }

    @media (max-width: $d_medium) {
        flex-direction: column;

        &--pdf Button {
            margin-top: $gap_items_20;
        }
    }
}

// ===== Loading
.MuiBackdrop-root {
    &.loader{
        background-color: $bg_color_primary;
    }
    .MuiCircularProgress-root {
        color: $color_primary;
        width: 4rem !important;
        height: 4rem !important;
    }
    
}

// ===== TABLES
.fm_table {
    .css-dsuxgy-MuiTableCell-root {
        font-size: $fs_S;
        font-family: $font_primary;
    }
    .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
        padding: 0;
    }
    .MuiButtonBase-root{
        padding: 0;
    }
    td {
        border-bottom: none;
    }

    tbody tr:first-child td{
        padding-top: 12px;
    }

    &--head {

        width: 100%;

        .fm_tb_name {
            background-color: $color_primary;
        }

        .fm_tb_name:first-child {
            border-radius: 8px 0 0 8px;
        }

        .fm_tb_name:last-child {
            border-radius: 0 8px 8px 0;
        }
    }

    &__selector {
        margin-bottom: $gap_items_20;
        display: flex;
        gap: $gap_items_20;
    }

    &--pagination {
        margin-top: 16px;

        li .MuiButtonBase-root {
            border-radius: $border_r_8;
            border-style: none;
        }

        .css-1omp2b7.Mui-selected {
            background-color: $color_primary;
        }

        &--rows {
            gap: 10px;

            @media (max-width: $d_medium) {
                margin-bottom: 16px;
            }

            &--input {
                padding: 0;
                height: 40px;
                width: auto;

                svg {
                    right: 2px;
                }

                .MuiSelect-select {
                    padding-left: 8px;
                    padding-right: 24px !important;
                    text-align: center;
                }
            }
        }

        .css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
            background-color: $color_primary;

            &:hover {
                background-color: darken($color_primary, 4);
            }
        }

        .rpb-root {
            li {
                @media (max-width: $d_medium) {
                    margin: 4px;
                }
            }
        }
    }

    &--actions {
        gap: 16px;
    }

    .fm_tb_name {
        &.MuiTableCell-root {
            color: $color_text_white;
            font-size: $fs_S;
            font-weight: $fw_bold;
            font-family: $font_primary;
            height: 48px;
        }
    }

    .fm_tb_cell {
        &.MuiTableCell-root {
            color: $color_text_black;
            font-size: $fs_S;
            font-weight: $fw_regular;
            font-family: $font_primary;
            height: 14px;
        }
    }

    .fm_cell_h{
        height: 48px;
    }
    .fm-white-icon{
        color: $color_text_white;
        &:hover{
            color: $color_text_gray;
        }
        .MuiSvgIcon-root {
            color: $color_text_white;
        }
    }

    .fm-pcolor-icon {
        color: $color_primary;
        .MuiSvgIcon-root{
            color: $color_primary;
        }
    }

    .fm_subtable {
        background-color: #F4F5F7;
        padding: 0;

        .MuiBox-root {
            margin: 0;
        }

        .css-1howxi1-MuiTableCell-root .MuiTableCell-root {
            border: none;
        }

        &--subcell {
            font-weight: $fw_bold;
            color: $color_text_gray;
            border: none;

            &--name {
                font-weight: $fw_bold;
                border: none;
                padding: 14px;
                color: $color_text_black;
            }

            &--table {
                padding: 0;
            }
        }
    }
}

.MuiPaper-root,
.MuiPaper-elevation,
.MuiPaper-rounded,
.MuiPaper-elevation1,
.MuiTableContainer-root,
.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
}


//==============================RESPONSIVE
@media (max-width: $d_medium) {
    .fm_main_container {
        padding: 0 20px;

        &--topheader {
            padding: 0 20px;
        }
    }

    .fm_card {
        padding: $padding_s;
    }

    .fm_mainTitle {
        margin-top: $gap_items_20;
        margin-bottom: $gap_items_20;

        &--bkbtn {
            margin-bottom: $gap_items_20;
        }

        h1 {
            line-height: 30px;
        }

        .orderNumber {
            margin-top: $gap_items_20;
        }

    }
}