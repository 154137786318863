.fm_topheader {
    background-color: $bg_color_primary;
    height: 60px;
}

.fm_th_btn {
    cursor: pointer;
    color: $color_primary;

    p {
        max-width: 120px;
        font-size: $fs_S;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        @media (max-width: $d_small) {
            max-width: 120px;
        }
    }
}