.fm_cvD_tData{
    margin-bottom: 30px;
    &__c{
        gap:$gap_items_20;
        p{
            font-size: $fs_S;
            font-weight: $fw_bold;
            color: $color_text_gray;
        }
        h3{
            font-size: 22px;
            font-weight: $fw_regular;
            color: $color_primary;
        }
        &--red h3{
            color: $color_text_red;
        }
        &--green h3 {
            color: $color_text_green;
        }
    }
    @media (min-width: $d_medium) {
        display: flex;
        justify-content: space-between;
        &__c {
            &--sc {
                gap: 30px;
            }
        }
    }
    @media (max-width: $d_medium) {
        flex-direction: column;
        &__c div{
            Width: 50%;
            gap: $gap_items_20;
        }
        &__c:first-child {
            margin-bottom: $gap_items_20;
        }
        &__c:last-child {
            flex-direction: column;
            width: 100%;
            .fm_cvD_tData__c--sc {
                width: 100%;
            }
        }
    }
}