.fm_rp{
    gap: 20px;
    max-width: 497px;
    width: 100%;
    p{
        font-size: $fs_S;
    }
    div{
        border-radius: $border_r_8;
    }
    &--gap{
        gap: $gap_items_20;
    }
    .MuiPaper-root{
        display: flex;
        justify-content: center;
    }
}