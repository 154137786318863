.fm_checkview {
    gap: 20px;
}

.fm_cview_filters {
    gap: $gap_items_30;
}

//==========CHAR
.fn_char_container {
    .css-1j30m50 {
        padding: 0;
        padding-bottom: 0;
    }

    .apexcharts-canvas{
    width: 100% !important;
        svg{
            width: 100%;
        }
    }

    .apexcharts-toolbar {
        display: none;
    }
}

.apexcharts-text tspan {
    font-size: $fs_S;
    color: $color_text_gray;
}

.fm_char_buttons {
    gap: $gap_items_20;
    .fm_char_btn {
        height: 24px;
        width: 24px;
        color: $color_text_gray;
        font-size: 24px;
        &:first-child{
            svg{
                width: 24px;
            }
        }
    }
}

.apexcharts-tooltip{
    display: none !important;
}

.fm_cview_filters {
    margin-left: 0;
    margin-right: 0;
}

.MuiFormControl-root {
    & label.Mui-focused{
        color: #91969A
    }
    & .MuiInput-underline:after{
        border-bottom-color: #CCCCCC
    }
    & .MuiOutlinedInput-root{
        & fieldset{
            border-color: #CCCCCC
        }
        &:hover fieldset{
            border-color: #CCCCCC
        }
        &.Mui-focused fieldset{
            border-color: #91969A
        }
    }
}
